<template>
  <b-row>
    <b-col md="12">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="float-right mb-1"
        onclick="history.back()"
      >
        <feather-icon icon="ArrowLeftCircleIcon" />
        Retour
      </b-button>
    </b-col>
    <b-col md="12">
      <b-tabs
        vertical
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
      >

        <!-- general tab -->
        <b-tab active>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="UserIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Général</span>
          </template>

          <b-card>

            <!-- form -->
            <b-form
              class="mt-2"
              @submit.prevent="update"
            >
              <b-row>
                <!-- alert -->
                <b-col
                  cols="12"
                  class="mt-75 mb-1"
                >
                  <b-alert
                    show
                    :variant="user.status ==='CREATED'?'warning':'success'"
                    class="mb-50"
                  >
                    <div class="alert-body">
                      <span>
                        <feather-icon
                          :icon="user.status === 'CREATED' ? 'AlertTriangleIcon' :'CheckSquareIcon'"
                          size="16"
                        />
                        {{ user.status === 'CREATED' ? 'Profil non vérifié' :'Profil vérifié' }}
                      </span>
                    </div>
                  </b-alert>
                </b-col>
                <!--/ alert -->
                <b-col sm="6">
                  <b-form-group
                    label="Prénoms"
                    label-for="account-firstname"
                  >
                    <b-form-input
                      v-model="form.firstname"
                      placeholder="Prénoms"
                      name="firstname"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Nom"
                    label-for="account-lastname"
                  >
                    <b-form-input
                      v-model="form.lastname"
                      name="lastname"
                      placeholder="Nom"
                    />
                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="E-mail"
                    label-for="account-e-mail"
                  >
                    <b-form-input
                      v-model="user.email"
                      name="email"
                      readonly
                      placeholder="Email"
                    />

                  </b-form-group>
                </b-col>
                <b-col sm="6">
                  <b-form-group
                    label="Société"
                    label-for="account-company"
                  >
                    <b-form-input
                      v-model="form.company"
                      name="company"
                      placeholder="Société"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    type="submit"
                    :disabled="userProcess"
                    class="mt-2 mr-1 float-right"
                  >
                    Sauvegarder
                    <b-spinner
                      v-if="userProcess"
                      small
                      label="Loading..."
                      variant="light"
                      class="ml-1"
                    />
                  </b-button>

                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-tab>
        <!--/ general tab -->

        <!-- Modifier mot de passe tab -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="LockIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Modifier mot de passe</span>
          </template>
          <b-alert
            variant="dark"
            show
          >
            <h4 class="alert-heading">
              Information
            </h4>
            <div class="alert-body">
              Votre mot de passe doit:
              <ul>
                <li>avoir au moins 8 caractères</li>
                <li>contenir au moins une lettre minuscule</li>
                <li>contenir au moins une lettre majuscule</li>
                <li>contenir au moins un chiffre</li>
                <li>contenir un caractère spécial</li>
              </ul>
            </div>
          </b-alert>
          <!-- form -->
          <b-form @submit.prevent="updateMotPassword">
            <b-row>
              <!-- old password -->
              <b-col md="6">
                <b-form-group
                  label="Ancien mot de passe"
                  label-for="account-old-password"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-old-password"
                      v-model="formPassword.current_password"
                      name="old-password"
                      :type="passwordFieldTypeOld"
                      placeholder="Ancien mot de passe"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconOld"
                        class="cursor-pointer"
                        @click="togglePasswordOld"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ old password -->
            </b-row>
            <b-row>
              <!-- new password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-new-password"
                  label="Nouveau mot de passe"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-new-password"
                      v-model="formPassword.password"
                      :type="passwordFieldTypeNew"
                      name="new-password"
                      placeholder="Nouveau mot de passe"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconNew"
                        class="cursor-pointer"
                        @click="togglePasswordNew"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ new password -->

              <!-- retype password -->
              <b-col md="6">
                <b-form-group
                  label-for="account-retype-new-password"
                  label="Confirmer mot de passe"
                >
                  <b-input-group class="input-group-merge">
                    <b-form-input
                      id="account-retype-new-password"
                      v-model="formPassword.password_confirmation"
                      :type="passwordFieldTypeRetype"
                      name="retype-password"
                      placeholder="Nouveau mot de passe"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIconRetype"
                        class="cursor-pointer"
                        @click="togglePasswordRetype"
                      />
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!--/ retype password -->

              <!-- buttons -->
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  class="mt-1 mr-1 float-right"
                  type="submit"
                  :disabled="userProcess"
                >
                  Sauvegarder
                  <b-spinner
                    v-if="userProcess"
                    small
                    label="Loading..."
                    variant="light"
                    class="ml-1"
                  />
                </b-button>
              </b-col>
              <!--/ buttons -->
            </b-row>
          </b-form>
        </b-tab>
        <!--/ Modifier mot de passe tab -->

        <!-- info -->
        <b-tab>

          <!-- title -->
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="18"
              class="mr-50"
            />
            <span class="font-weight-bold">Information</span>
          </template>

          <b-card>

            <!-- form -->
            <b-form @submit.prevent="update">
              <b-row>

                <!-- birth date -->
                <b-col md="6">
                  <b-form-group
                    label-for="example-datepicker"
                    label="Date de naissance"
                  >
                    <flat-pickr
                      v-model="form.birthday"
                      class="form-control"
                      name="date"
                      :config="config"
                      placeholder="Date de naissance"
                    />
                  </b-form-group>
                </b-col>
                <!--/ birth date -->
                <!-- website -->
                <b-col md="6">
                  <b-form-group
                    label-for="role"
                    label="Rôle"
                  >
                    <b-form-input
                      id="role"
                      v-model="role"
                      placeholder="Rôle"
                    />
                  </b-form-group>
                </b-col>
                <!--/ website -->
                <!-- website -->
                <b-col md="6">
                  <b-form-group
                    label-for="adresse"
                    label="Adresse"
                  >
                    <b-form-input
                      id="adresse"
                      v-model="form.adresse"
                      placeholder="Adresse"
                    />
                  </b-form-group>
                </b-col>
                <!--/ website -->

                <!-- phone -->
                <b-col md="6">
                  <b-form-group
                    label-for="phone"
                    label="Télephone"
                  >
                    <cleave
                      id="phone"
                      v-model="form.telephone"
                      class="form-control"
                      :raw="false"
                      :options="clevePhone"
                      placeholder="Numéro de téléphone"
                    />
                  </b-form-group>
                </b-col>
                <!-- phone -->

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    size="sm"
                    class="mt-1 mr-1 float-right"
                    type="submit"
                    :disabled="userProcess"
                  >
                    Sauvegarder
                    <b-spinner
                      v-if="userProcess"
                      small
                      label="Loading..."
                      variant="light"
                      class="ml-1"
                    />
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card>
        </b-tab>

      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import {
  BSpinner,
  BTabs, BTab, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useAuthUser from '@/services/authentification/userService'
// eslint-disable-next-line import/no-extraneous-dependencies
import { onMounted, reactive } from 'vue-demi'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
// eslint-disable-next-line import/no-extraneous-dependencies, import/order
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BInputGroupAppend,
    flatPickr,
    BInputGroup,
    Cleave,
    BSpinner,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  setup() {
    const {
      user, loader, getAuthUser, role, updateUserInformation, userProcess, updatePassword,
    } = useAuthUser()

    const form = reactive({
      lastname: '',
      firstname: '',
      telephone: '',
      adresse: '',
      birthday: '',
    })
    const formPassword = reactive({
      current_password: '',
      password: '',
      password_confirmation: '',
    })
    /* eslint-disable global-require */
    const item = user
    const clevePhone = {
      phone: true,
      phoneRegionCode: 'BJ',
    }
    onMounted(async () => {
      await getAuthUser()
      form.lastname = user.value.lastname
      form.firstname = user.value.firstname
      form.telephone = user.value.telephone
      form.adresse = user.value.adresse
      form.birthday = user.value.birthday
    })
    const config = {
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'j M  Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: French, // locale for this instance only
    }

    const update = async () => {
      await updateUserInformation({ ...form })
    }
    const updateMotPassword = async () => {
      await updatePassword({ ...formPassword })
    }

    return {

      item,
      user,
      form,
      config,
      role,
      formPassword,
      update,
      userProcess,
      clevePhone,
      updateMotPassword,
      loader,

    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
